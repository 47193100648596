import * as React from 'react';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';
import { Link } from 'gatsby';
import { colors, fonts } from '../styles/variables';
import logo from '../images/logo_small.png';

const StyledHeading = styled.h1`
  transition: ${p => p.theme.colorModeTransition};
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 13px;
  font: ${fonts.sansSerif};
  font-size: 40px;

  a {
    transition: ${p => p.theme.colorModeTransition};
  }

  a,
  a:hover,
  a:visited {
    color: ${p => p.theme.colors.primary};
    text-decoration: none;
  }

  @media only screen and (max-width: 973px) {
    margin-top: 0;
    .pipe {
      opacity: 0;
    }
  }
`;

const StyledSubHeading = styled.span`
  opacity: 0.6;
  font-size: 9.5px;
  font-weight: normal;
  margin-top: -6px;
  display: block;
  margin-left: 38px;
`;

const StyledBlurb = styled.h2`
  margin-top: 25px;
  margin-bottom: 45px;
  color: ${p => p.theme.colors.primary};
  transition: ${p => p.theme.colorModeTransition};
  font-weight: normal;
  font-size: 1.08em;
`;

const StyledQuote = styled.span`
  font-family: ${p => p.theme.fonts.serif};
  font-style: italic;
  font-weight: 900;
  font-size: 1.01em;
`;

function RenderedDateTime({ time = 0 }) {
  const unix = ((time / 1000) | 0) - 5 * 60 * 60;
  const est = new Date(unix * 1000);
  return <>{est.toUTCString().replace('GMT', 'EST')}</>;
}

const StyledTagLine = styled.span`
  font-family: ${p => p.theme.fonts.serif};
  vertical-align: text-top;
  font-weight: 400;
  font-size: 0.64em;
  display: inline-block;
  font-style: italic;
  color: ${p => p.theme.colors.grey};
  padding-right: 70px;

  @media only screen and (max-width: 973px) {
    margin-top: 15px;
  }

  @media only screen and (max-width: 800px) {
    padding-right: 0px;
  }
`;

const StyledHeaderDisclaimer = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.white};
  padding: 5px 5px;
  font-size: 0.8em;
  margin-top: -15px;
  line-height: 1.4em;
  border-radius: 1.5px;
  text-align: center;
`;

const StyledLogo = styled.img`
  width: 24px;
  //margin-left: -32px;
  margin-right: 8px;
`;

const StyledNavbarButton = styled.span`
  background: ${p => p.theme.colors.card};
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28150, 150, 150, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 32px;
  height: 32px;
  border: 1px solid ${p => p.theme.colors.horizontalRule};
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 0;
  transition: all 0.125s;
  z-index: 10;

  :hover {
    border-width: 2px;
  }
`;

const StyledNavMenu = styled.div`
  background: ${p => p.theme.colors.card};
  position: absolute;
  right: 0;
  top: 33px;
  z-index: 1000;
  border: 1px solid ${p => p.theme.colors.horizontalRule};
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px 4px 20px;
  padding: 5px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 5px;
      padding-right: 10px;
    }
  }
`;

const DarkModeToggle: React.FC<{}> = () => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;

  function toggleColorMode(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    try {
      const themeName = isDark ? `light` : `dark`;
      if (gtag) {
        gtag('event', `theme_${themeName}`, { name: themeName });
      }
    } catch (e) {
      // nop
    }
    setColorMode(isDark ? `light` : `dark`);
  }

  return (
    <IconWrapper
      isDark={isDark}
      onClick={toggleColorMode}
      data-a11y="false"
      aria-label={isDark ? 'Activate light mode' : 'Experimental Dark Mode'}
      title={isDark ? 'Activate light mode' : 'Experimental Dark Mode'}
    >
      <MoonOrSun isDark={isDark} />
      <MoonMask isDark={isDark} />
    </IconWrapper>
  );
};

export function PageHeader({ logoOnly = false, time = new Date().getTime() }) {
  const [showMenu, setShowMenu] = React.useState(false);

  React.useEffect(() => {
    if (showMenu) {
      const handle = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target?.href) {
          setShowMenu(false);
        }
      };
      document.addEventListener('mousedown', handle);
      return () => {
        document.removeEventListener('mousedown', handle);
      };
    }
  }, [showMenu]);

  return (
    <>
      <StyledHeading>
        <Link style={{ display: 'inline-block' }} to="/">
          <StyledLogo src={logo} alt="logo" />
          <b>wsb</b>daily <span className="pipe"> | </span>
          <StyledSubHeading>
            <RenderedDateTime time={time} />
          </StyledSubHeading>
        </Link>{' '}
        <StyledTagLine>Where you find Tomorrow’s Hot Stocks Yesterday!</StyledTagLine>
        <StyledNavbarButton
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowMenu(s => !s);
          }}
        >
          &nbsp;
        </StyledNavbarButton>
        <DarkModeToggle />
      </StyledHeading>
      <StyledNavMenu style={{ display: showMenu ? '' : 'none' }}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/penny/">Penny Stocks</Link>
          </li>
          <li>
            <Link to="/canada/">Canadian</Link>
          </li>
          <li>
            <Link to="/SPACs/">SPACs</Link>
          </li>
        </ul>
      </StyledNavMenu>
      {!logoOnly && (
        <StyledBlurb>
          <b>Welcome!</b> As the great Eddie Morra once said{' '}
          <StyledQuote>"Share prices aren't really based on how a company works it's about how mass psychology works"</StyledQuote> so here
          you'll find the hottest stocks from the top online investing communities.
        </StyledBlurb>
      )}
      <StyledHeaderDisclaimer style={{ marginTop: !logoOnly ? -15 : 35 }}>
        NOT INVESTMENT ADVICE: ALL CONTENT IS PROVIDED 'AS IS' FOR ENTERTAINMENT AND/OR INFORMATIONAL PURPOSES ONLY.
        <br />
        WITHOUT WARRANTY OF ANY KIND. YOU BEAR ALL RISKS, INCLUDING ANY RELIANCE ON THE ACCURACY OF ANY INFORMATION.
        <br />
        WE ARE NOT AFFILIATED WITH THE WALLSTREETBETS NOR ANY OTHER COMMUNITY ON REDDIT.
      </StyledHeaderDisclaimer>
    </>
  );
}

const IconWrapper = styled.button<{ isDark: boolean }>`
  position: absolute;
  right: 36px;
  top: 11px;
  z-index: 5;

  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  cursor: pointer;

  opacity: 0.5;
  //position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-left: 30px;
  &:hover {
    opacity: 1;
  }
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`;

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div<{ isDark: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${p => (p.isDark ? '4px' : '2px')} solid ${p => p.theme.colors.primary};
  background: ${p => p.theme.colors.primary};
  transform: scale(${p => (p.isDark ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${p => (p.isDark ? 'visible' : 'hidden')};
  &::before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid ${p => p.theme.colors.primary};
    border-radius: 50%;
    transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
    opacity: ${p => (p.isDark ? 0 : 1)};
    transition: transform 0.45s ease;
  }
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${p => p.theme.colors.primary}, 0 23px 0 ${p => p.theme.colors.primary}, 23px 0 0 ${p => p.theme.colors.primary},
      -23px 0 0 ${p => p.theme.colors.primary}, 15px 15px 0 ${p => p.theme.colors.primary}, -15px 15px 0 ${p => p.theme.colors.primary},
      15px -15px 0 ${p => p.theme.colors.primary}, -15px -15px 0 ${p => p.theme.colors.primary};
    transform: scale(${p => (p.isDark ? 1 : 0)});
    transition: all 0.35s ease;
  }
`;

const MoonMask = styled.div<{ isDark: boolean }>`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: ${p => p.theme.colors.background};
  transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
  opacity: ${p => (p.isDark ? 0 : 1)};
  transition: ${p => p.theme.colorModeTransition}, transform 0.45s ease;
`;

const Hidden = styled.span`
  position: absolute;
  display: inline-block;
  opacity: 0;
  width: 0px;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
`;
